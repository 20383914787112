import React from "react";
import PropTypes from "prop-types";

const CustomDropDown = (props) => {
    const { name, label, values, setState } = props;
    return (
        <>
            <label
                className="block uppercase tracking-wide dark:text-slate-400 text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
            >
                {label}
            </label>
            <select
                className="block appearance-none w-full dark:bg-slate-700 dark:text-gray-300 dark:focus:bg-slate-600 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id={`select_${name}`}
                onChange={(e) => {
                    setState(e.target.value);
                }}
            >
                {values.map(function (value) {
                    return (
                        <option
                            defaultValue={value.value}
                            value={value.value}
                            key={value.value}
                        >
                            {value.name}
                        </option>
                    );
                })}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
            </div>
        </>
    );
};

CustomDropDown.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    values: PropTypes.array,
    setState: PropTypes.any,
};

export default CustomDropDown;
