import React, { useEffect, useState } from "react";
import CustomDropDown from "../Form/CustomDropDown";
import CustomInput from "../Form/CustomInput";
import { fetchDiscordUser } from "../../Hooks/fetchDiscrodUser";
import { Rules } from "../../Data/Rules";

const CECommands = (props) => {
    const { setHistory } = props;

    const [discordId, setDiscordId] = useState("");
    const [reason, setReason] = useState("");
    const [type, setType] = useState("w");
    const [result, setResult] = useState("");
    const [user, setUser] = useState("");
    const [rule, setRule] = useState("");

    const types = [
        { name: "Warn", value: "w" },
        { name: "Kick", value: "k" },
        { name: "Tempban", value: "tb" },
        { name: "Softban", value: "sb" },
        { name: "Ban", value: "b" },
        { name: "Unban", value: "ub" },
    ];

    const rules = Rules;

    const onGenerate = () => {
        let resetFields = false;
        if (type !== "ub" && type.length > 0 && reason.length > 0) {
            const ruleText = rule.length > 0 ? `${rule}\r\n\r\n` : "";
            const generatedResult = `^${type} ${discordId} ${ruleText}${reason}`;
            setResult(generatedResult);
            navigator.clipboard.writeText(generatedResult);
            resetFields = true;

            setHistory((oldArray) => [
                {
                    category: "CE Command",
                    user: discordId,
                    type: types.find((x) => x.value === type).name,
                    reason: reason,
                    command: generatedResult,
                },
                ...oldArray,
            ]);
        } else if (type === "ub" && type.length > 0) {
            const generatedResult = `^${type} ${discordId}`;
            setResult(generatedResult);
            navigator.clipboard.writeText(generatedResult);
            resetFields = true;

            setHistory((oldArray) => [
                {
                    category: "CE Command",
                    user: discordId,
                    type: types.find((x) => x.value === type).name,
                    reason: "",
                    command: generatedResult,
                },
                ...oldArray,
            ]);
        } else {
            setResult("Invalid Result!");
        }

        if (resetFields) {
            setDiscordId("");
            setReason("");
            setUser("");
        }
    };

    // useEffect(() => {
    //     if (discordId && discordId.length >= 17) {
    //         fetchDiscordUser(discordId)
    //             .then((result) => {
    //                 return result.json();
    //             })
    //             .then((data) => {
    //                 setUser(data.data);
    //             });
    //     }
    // }, [discordId]);

    return (
        <div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <CustomDropDown
                        name="types"
                        label="Types"
                        values={types}
                        setState={setType}
                    />
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <CustomInput
                        name="discordId"
                        label="Discord ID"
                        placeholder="Discord ID"
                        isRequired={true}
                        state={discordId}
                        setState={setDiscordId}
                    />
                    {/* <div className="w-full">
                        {user ? (
                            <p className="dark:text-slate-400">
                                <small>
                                    Please confirm this is the correct user:
                                </small>{" "}
                                <img
                                    src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png?size=1024`}
                                    width={50}
                                    alt={user.username}
                                />
                                {user.username}
                            </p>
                        ) : (
                            <p className="text-red-700">
                                <strong>Discord ID is invalid.</strong>
                            </p>
                        )}
                    </div> */}
                </div>
            </div>
            {type && type !== "ub" && (
                <>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <CustomDropDown
                                name="rule"
                                label="Rule Broken"
                                isRequired
                                values={rules}
                                setState={setRule}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <CustomInput
                                name="reason"
                                label="Reason"
                                placeholder="Reason"
                                isRequired={true}
                                state={reason}
                                setState={setReason}
                            />
                        </div>
                    </div>
                </>
            )}

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <label
                        className="block uppercase tracking-wide text-gray-700 dark:text-slate-400 text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                    >
                        Result
                    </label>
                    <textarea
                        value={result}
                        rows={10}
                        className="appearance-none block w-full dark:bg-slate-700 dark:text-gray-300 dark:focus:bg-slate-600 bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    >
                        {result}
                    </textarea>
                </div>
            </div>

            <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={onGenerate}
            >
                Generate & Copy
            </button>
        </div>
    );
};

export default CECommands;
