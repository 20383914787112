import { useState, useEffect } from "react";
import CECommands from "./Components/Sections/CECommands";
import MuteCommands from "./Components/Sections/MuteCommands";
import History from "./Components/Sections/History";

function App() {
    /*
        {
            category: "",
            user: "",
            type: "",
            reason: "",
            command:"",
        }[]
    */
    const [history, setHistory] = useState([]);

    useEffect(() => {
        document.body.classList.add("dark:bg-slate-800");

        const storedHistory = JSON.parse(sessionStorage.getItem("history"));

        if (history.length === 0 && storedHistory) {
            setHistory(storedHistory);
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem("history", JSON.stringify(history));
    }, [history]);

    return (
        <div className="container flex flex-wrap mx-auto">
            <div className="w-full xl:w-full p-5 xl:border-gray-200 ">
                <h2 className="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
                    CE Commands
                </h2>
                <CECommands setHistory={setHistory} />
            </div>
            <div className="w-full xl:w-full p-5">
                <h2 className="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
                    Mute Commands
                </h2>
                {/* <MuteCommands setHistory={setHistory} /> */}
                <p className="text-gray-700 dark:text-slate-400">
                    Don't use this anymore, BeanBot is better!
                </p>
            </div>
            <div className="w-full xl:w-full p-5">
                <h2 className="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
                    Command History
                </h2>
                <History history={history} />
            </div>
        </div>
    );
}

export default App;
