import React from "react";
import PropTypes from "prop-types";

const CustomInput = (props) => {
    const {
        label,
        name,
        placeholder,
        isRequired = false,
        state,
        setState,
    } = props;
    return (
        <>
            <label
                className="block uppercase tracking-wide text-gray-700 dark:text-slate-400 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
            >
                {label}
            </label>
            <input
                className={`appearance-none block w-full dark:bg-slate-700 dark:text-gray-300 dark:focus:bg-slate-600 bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
                    isRequired && !state && "border-red-500"
                }`}
                id={`input_${name}`}
                name={`${name}`}
                value={state}
                type="text"
                placeholder={placeholder}
                required={isRequired}
                onChange={(e) => {
                    setState(e.target.value);
                }}
            />
            {isRequired && !state && (
                <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                </p>
            )}
        </>
    );
};

CustomInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    state: PropTypes.string,
    setState: PropTypes.any,
};

export default CustomInput;
