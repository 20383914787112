export const Rules = [
    {
        name: "Do Not Include a Rule",
        value: "",
    },
    {
        name: "Rule 1 - Be Respectful",
        value: "> **Rule 1**\r\n > Be respectful to everyone.",
    },
    {
        name: "Rule 2 - Do not @ CIG",
        value: "> **Rule 2**\r\n > Do not '@' mention CIG employees unless they are actively participating in the chat. Same goes for sending them unwarranted DMs or friend requests.",
    },
    {
        name: "Rule 3 - No referral codes",
        value: "> **Rule 3**\r\n > No referral codes. If you're looking for a referral code, use https://gorefer.me/randomizer/STAR.",
    },
    {
        name: "Rule 4 - No trading or selling for any items or begging",
        value: "> **Rule 4**\r\n > No trading or selling for any items; virtual or physical except in <#1019852856460853258> and <#972321226854916096>. Real money trading, begging (even as a joke), or spamming is always prohibited.",
    },
    {
        name: "Rule 5 - No advertising or Self-promotion",
        value: "> **Rule 5**\r\n > Self-promotion, asking for upvotes, advertising, and so on is not allowed. You may share your creations in <#364579118370521098>. Strictly NO Twitch links or Streams.",
    },
    {
        name: "Rule 6 - No Discord invitations",
        value: "> **Rule 6**\r\n > Discord invitations are not allowed anywhere in the server, including unsolicited invites through DMs using r/starcitizen as a mutual server.",
    },
    {
        name: "Rule 7 - Channel topic guidelines",
        value: "> **Rule 7**\r\n > Please keep topics and messages within their respective channels if one exists for it. Channel topic guidelines must also be adhered to at all times.",
    },
    {
        name: "Rule 8 - No Org activity",
        value: "> **Rule 8**\r\n > Organization activity is not allowed on this server; absolutely no unsolicited org advertisements, messages, or recruitment. This extends to those that are looking for an org as well. Use the /r/starcitizen_guilds subreddit or the resources on the official https://robertsspaceindustries.com/ website.",
    },
    {
        name: "Rule 9 - No political discussions",
        value: "> **Rule 9**\r\n > Political discussion, posts, or memes whether left or right, authoritative or liberal, pro X or anti Y is not welcome within the server.",
    },
    {
        name: "Rule 10- These rules are not up for personal interpretations",
        value: "> **Rule 10**\r\n > These rules are not up for personal interpretations. Mods enforce these rules according to the spirit of these rules and arguing that a certain behavior is not explicitly mentioned in the rules may lead to a kick/ban. If we feel you're being disruptive or a detriment to the culture of the server, we will remove you.",
    },
];
